<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2" class="bg-light-blue">
        <v-card>
          <v-list>
            <v-list-item @click="currentPage = 'dashboard'">
              <v-list-item-action>
                <!-- <v-icon>mdi-view-dashboard</v-icon> -->
                <span class="material-symbols-outlined">dashboard </span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'dashboard' }">Dashboard</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="canViewBooking" @click="currentPage = 'bookings'">
              <v-list-item-action>
                <span class="material-symbols-outlined">airplane_ticket</span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'bookings' }">All Bookings</v-list-item-content>
            </v-list-item>
            
            <v-list-item @click="currentPage = 'myBookings'">
              <v-list-item-action>
                <span class="material-symbols-outlined">airplane_ticket</span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'myBookings' }">My Bookings</v-list-item-content>
            </v-list-item>
            <v-list-item @click="currentPage = 'summary'">
              <v-list-item-action>
                <span class="material-symbols-outlined">summarize</span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'summary' }">Summary</v-list-item-content>
            </v-list-item>
            <!-- <v-list-item @click="currentPage = 'passengers'">
              <v-list-item-action>
                <v-icon>mdi-account-passenger</v-icon>
              </v-list-item-action>
              <v-list-item-content>Passenges</v-list-item-content>
            </v-list-item> -->
            <!-- Add more items here -->
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card v-if="currentPage === 'dashboard'">
          <!-- <v-card-title>Dashboard</v-card-title> -->
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Dashboard</v-card-title>
          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card-title class="justify-center"><h6>Total Booking (Unpaid)</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="donut" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 5 content here -->
                <v-card-title class="justify-center"><h6>Polar Total Booking</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="polarArea" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 6 content here -->
                <v-card-title class="justify-center"><h6>Another Booking Group</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="pie" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card-title class="justify-center"><h6>Total User Group</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="donut" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 5 content here -->
                <v-card-title class="justify-center"><h6>Polar User Group</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="polarArea" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 6 content here -->
                <v-card-title class="justify-center"><h6>User Group with SP</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="donut" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'bookings'">
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Bookings</v-card-title>
          <v-card-text>
            <v-data-table :headers="bookingHeaders" :items="bookings" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100, 500] }">
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.tour_id="{ item }">
                <span class="text-uppercase" style="color: #00657D; font-size: 0.9em; font-weight: bold;">{{ item.tour.tour_group.tour_group_name }}</span>
              </template>
              <template v-slot:item.pax="{ item }">
                <!-- {{ item.tour.user_id }} -->
                <div style="text-align: center">
                  {{ item.passengers.length }} Pax
                </div>
              </template>
              <template v-slot:item.user_id="{ item }">
                <!-- {{ item.user.name }} -->
                {{ item.user.name.charAt(0).toUpperCase() + item.user.name.slice(1)  }} 
              </template>
              <template v-slot:item.date="{ item }">
                <!-- <div class="text-right">{{ item.date }}</div> -->
                <div>{{ formatDate(item.date) }}</div>
              </template>
              <template v-slot:item.total="{ item }">
                <!-- {{ item.total.toFixed(2) }} -->
                <!-- <div class="text-right">{{ item.total.toFixed(2) }}</div> -->
                <div class="text-right">{{ $formatCurrency(item.total) }}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn small color="blue darken-1" text @click="showBookingDetails(item)">
                  <v-icon small>mdi-eye</v-icon>
                  View
                </v-btn>
                <!-- <v-btn small color="blue darken-1" text @click="showEditBookingPage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn> -->
                <v-btn v-if="canDeleteBooking" small color="red darken-1" text @click="startDeletingBooking(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
            <!-- <v-btn @click="currentPage = 'addBooking'">Add Booking</v-btn> -->
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'myBookings'">
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Bookings</v-card-title>
          <v-card-text>
            <v-data-table :headers="mybookingHeaders" :items="myBookings" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100, 500] }">
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.tour_id="{ item }">
                <span class="text-uppercase" style="color: #00657D; font-size: 0.9em; font-weight: bold;">{{ item.tour.tour_group.tour_group_name }}</span>
              </template>
              <template v-slot:item.pax="{ item }">
                <!-- {{ item.tour.user_id }} -->
                <div style="text-align: center">
                  {{ item.passengers.length }} Pax
                </div>
              </template>
              <template v-slot:item.user_id="{ item }">
                <!-- {{ item.user.name }} -->
                {{ item.user.name.charAt(0).toUpperCase() + item.user.name.slice(1)  }} 
              </template>
              <template v-slot:item.date="{ item }">
                <!-- <div class="text-right">{{ item.date }}</div> -->
                <div>{{ formatDate(item.date) }}</div>
              </template>
              <template v-slot:item.total="{ item }">
                <!-- {{ item.total.toFixed(2) }} -->
                <!-- <div class="text-right">{{ item.total.toFixed(2) }}</div> -->
                <div class="text-right">{{ $formatCurrency(item.total) }}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn small color="blue darken-1" text @click="showBookingDetails(item)">
                  <v-icon small>mdi-eye</v-icon>
                  View
                </v-btn>
                <v-btn v-if="canDeleteBooking" small color="red darken-1" text @click="startDeletingBooking(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'bookingDetails'">
          <v-card-title v-if="currentBooking && currentBooking.user">{{ currentBooking.user.name.charAt(0).toUpperCase() + currentBooking.user.name.slice(1) }}'s Booking</v-card-title>
          <!-- <v-card-title v-if="currentBooking && currentBooking.user">{{ currentBooking.user.name }}'s Booking</v-card-title> -->
          <v-card-text v-if="currentBooking">
            <p>Tour: <b>{{ currentBooking.tour.tour_group.tour_group_name }}</b></p>
            <!-- <p>Travel Date: {{ currentBooking.date }}</p> -->
            <p>Travel Date: {{ $formatDate(currentBooking.date) }}</p>
            <!-- <p>Total Amount: RM {{ currentBooking.total.toFixed(2) }}</p> -->
            <p>Total Amount: {{ $formatCurrency(currentBooking.total) }}</p>
            <br />
            <h5>Travel Passenger(s):</h5>
            <v-data-table :headers="passengerHeaders" :items="currentBooking.passengers" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">
            <template v-slot:item.index="{ index }">
                {{ index + 1 }}
            </template>
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="canEditBooking" small color="blue darken-1" text @click="showEditPassengerPage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
                <!-- <v-btn small color="red darken-1" text @click="startDeletingPassenger(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn> -->
              </template>
          </v-data-table>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'summary'">
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Summary</v-card-title>
          <v-card-text>
            <v-data-table 
              :headers="summaryHeaders" 
              :items="summaryData" 
              :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
              hide-default-footer
            >
              <template v-slot:item.value="{ item }">
                {{ item.prefix }}{{ item.value }}{{ item.suffix }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <!-- <v-card v-if="currentPage === 'addBooking'">
          <v-card-title>Add Booking</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addBooking">
              <v-text-field label="Tour" type="text" v-model="tour_id" required></v-text-field>
              <v-text-field label="User" type="text" v-model="user_id" required></v-text-field>
              <v-text-field label="Date" type="text" v-model="date" required></v-text-field>
              <v-text-field label="Total" type="text" v-model="total" required></v-text-field>
              <v-btn @click="currentPage = 'bookings'">Back</v-btn>
              <v-btn type="submit">Add Booking</v-btn>
            </v-form>
          </v-card-text>
        </v-card> -->
        <!-- <v-card v-if="currentPage === 'editBooking'">
          <v-card-title>Edit Booking</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="editBooking">
              <v-text-field label="Tour" type="text" v-model="editingBooking.tour_id" required></v-text-field>
              <v-text-field label="User" type="text" v-model="editingBooking.user_id" required></v-text-field>
              <v-text-field label="Date" type="text" v-model="editingBooking.date" required></v-text-field>
              <v-text-field label="Total" type="text" v-model="editingBooking.total" required></v-text-field>
              <v-btn @click="currentPage = 'bookings'">Cancel</v-btn>
              <v-btn type="submit">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card> -->
        <!-- <v-card v-if="currentPage === 'passengers'">
          <v-card-title>Passengers</v-card-title>
          <v-card-text>
            <v-data-table :headers="passengerHeaders" :items="passengers" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
             
              <template v-slot:item.actions="{ item }">
                <v-btn small color="blue darken-1" text @click="showEditPassengerPage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
                <v-btn small color="red darken-1" text @click="startDeletingPassenger(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
            <v-btn @click="currentPage = 'addPassenger'">Add Passenger</v-btn>
          </v-card-text>
        </v-card> -->
        <!-- <v-card v-if="currentPage === 'addPassenger'">
          <v-card-title>Add Passenger</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addPassenger">
              <v-text-field label="Booking ID" type="text" v-model="booking_id" required></v-text-field>
              <v-text-field label="Designation" type="text" v-model="designation" required></v-text-field>
              <v-text-field label="Name" type="text" v-model="name" required></v-text-field>
              <v-text-field label="Passport" type="text" v-model="passport" required></v-text-field>
              <v-text-field label="Date Of Birth" type="text" v-model="date_of_birth" required></v-text-field>
              <v-text-field label="Phone Number" type="text" v-model="hp" required></v-text-field>
              <v-text-field label="Remark" type="text" v-model="remark"></v-text-field>
              <v-btn @click="currentPage = 'passengers'">Back</v-btn>
              <v-btn type="submit">Add Passenger</v-btn>
            </v-form>
          </v-card-text>
        </v-card> -->
        <v-card v-if="currentPage === 'editPassenger'">
          <v-card-title>Edit Passenger</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="editPassenger">
              <v-text-field label="Designation" type="text" v-model="editingPassenger.designation" required></v-text-field>
              <v-text-field label="Name" type="text" v-model="editingPassenger.name" required></v-text-field>
              <v-text-field label="Passport" type="text" v-model="editingPassenger.passport" required></v-text-field>
              <v-text-field label="Date Of Birth" type="text" v-model="editingPassenger.date_of_birth" required></v-text-field>
              <v-text-field label="Phone Number" type="text" v-model="editingPassenger.hp" required></v-text-field>
              <v-text-field label="Remark" type="text" v-model="editingPassenger.remark"></v-text-field>
              <!-- <v-file-input
                label="Passport Upload"
                @change="file => handlePassportUpload(file)"
                accept="image/*"
                :rules="[v => !!v || 'Passport upload is required']"
              ></v-file-input> -->
              <!-- <v-file-input
                label="Passport Upload"
                @change="handlePassportUpload"
                accept="image/*"
                :rules="[v => !!v || 'Passport upload is required']"
              ></v-file-input> -->
              <v-col cols="12" sm="6">
                <v-btn
                  color="primary"
                  @click="triggerFileInput"
                  v-if="!editingPassenger.passport_upload_url"
                >
                  Upload Passport
                </v-btn>
                <v-btn
                  color="error"
                  @click="clearPassportImage"
                  v-else
                >
                  Clear Image
                </v-btn>
                <span v-if="editingPassenger.passport_upload" class="ml-2">
                  {{ getFileName(editingPassenger.passport_upload) }}
                </span>
                <input
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  @change="handlePassportUpload"
                  accept="image/*"
                >
              </v-col>
              <v-col cols="12" sm="6">
                <v-img 
                  v-if="editingPassenger.passport_upload_url" 
                  :src="editingPassenger.passport_upload_url" 
                  alt="Passport Upload" 
                  style="max-width: 200px; max-height: 200px; object-fit: contain;"
                  @error="handleImageError"
                ></v-img>
              </v-col>
              <!-- <v-img
                :src="editingPassenger.passport_upload_url"
                alt="Passport Upload"
                style="max-width: 500px;"
              ></v-img> -->
              <!-- <v-img
                :src="editingPassenger.passport_upload_url"
                :lazy-src="'path/to/placeholder/image.jpg'"
                alt="Passport Upload"
                style="max-width: 500px;"
                @error="handleImageError"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img> -->
              <v-spacer style="padding-bottom: 40px;"></v-spacer>
              <v-btn @click="currentPage = 'bookingDetails'">Cancel</v-btn>
              <v-btn type="submit">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-dialog v-model="confirmDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirmDelete = false">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="confirmDeleteItem">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      currentPage: 'dashboard', // 'bookings', 'addBooking', 'editBooking', 'passengers', 'addPassenger', 'editPassenger'
      editingBooking: null,
      // bookingToDelete: null,
      // confirmDelete: false,
      // itemToDelete: null,
      confirmDelete: false,
      currentBooking: null,
      myBookings: [],
      bookings: [],
      passengers: [],
      passenger:{
        passport_upload_url: '',
      },
      editingPassenger:{
        passport_upload_url: '',
      },
      mybookingHeaders: [
        { text: 'No', value: 'index' },
        { text: 'Tour', value: 'tour_id' },
        { text: 'Passenger(s)', value: 'pax' },
        { text: 'Booking Date', value: 'date' },
        { text: 'Total (RM)', value: 'total' },
        { text: 'Actions', value: 'actions' },
      ],
      bookingHeaders: [
        { text: 'No', value: 'index' },
        { text: 'Tour', value: 'tour_id' },
        { text: 'Passenger(s)', value: 'pax' },
        { text: 'User/Agent', value: 'user_id' },
        { text: 'Booking Date', value: 'date' },
        { text: 'Total (RM)', value: 'total' },
        { text: 'Actions', value: 'actions' },
      ],
      passengerHeaders: [
        { text: 'No', value: 'index' },
        // { text: 'Booking ID', value: 'booking_id' },
        { text: 'Name', value: 'name' },
        { text: 'Passport', value: 'passport' },
        { text: 'Phone Number', value: 'hp' },
        { text: 'Designation', value: 'designation' },
        { text: 'Actions', value: 'actions' },
      ],
      labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      value: [10, 15, 7, 20, 10],
      groupSeries: [],
      // groupChartOptions: {
      //   chart: {
      //     type: 'donut',
      //   },
      //   labels: [], // 这里将用于显示组名
      // },
      groupChartOptions: {
        chart: {
          type: 'donut',
          height: 350
        },
        series: [{
          name: 'Sales',
          data: this.groupSeries // This assumes you're keeping the numerical data for charting
        }],
        tooltip: {
          y: {
            formatter: (val) => {
              // Now 'this' refers to the Vue instance, where $formatCurrency is defined
              const formattedValue = this.$formatCurrency(val); // Assuming 'RM' is default
              return formattedValue;
            }
          }
          
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     // return `Total: $${val.toFixed(2)}`;
        //   }
        // }
      },
      groupChartOptions2: {
        chart: {
          type: 'polarArea',
        },
        labels: [], // 这里将用于显示组名
      },
      groupDataLoaded: false,
      users: [],
      userTotals: [],
      // editingPassenger:{},
      // editingPassenger: {
      //   passport_upload: '',
      //   passport_upload_url: '',
      //   passport_upload_file: null,
      // },
      userId: JSON.parse(localStorage.getItem('user_id') || '[]'),
      summaryHeaders: [
        { text: 'Metric', value: 'metric' },
        { text: 'Value', value: 'value' },
      ],
      summaryData: [],
    }
  },
  mounted() {
    this.getMyBookings();
    this.getBookings();
    this.getPassengers();
    this.getUsers();
    this.getGroups().then(() => {
      // this.groupSeries = this.bookings.map(booking => booking.total);
      this.groupSeries = Object.values(this.bookings.reduce((acc, booking) => {
        if (!acc[booking.user_id]) {
          acc[booking.user_id] = 0;
        }
        acc[booking.user_id] += booking.total;
        return acc;
      }, {}));

      // this.groupChartOptions.labels = this.bookings.map(booking => booking.total);
      // this.groupChartOptions.labels = this.bookings.map(booking => booking.user_id);
      
      // Simple - show all unique user id
      // this.groupChartOptions.labels = [...new Set(this.bookings.map(booking => booking.user_id))];

      // Simple - show all user name based on users array
      // this.groupChartOptions.labels = this.bookings.map(booking => {
      //   const user = this.users.find(user => user.id === booking.user_id);
      //   return user? user.name : '';
      // });

      // New method to sort user id accordingly
      const userIds = [...new Set(this.bookings.map(booking => booking.user_id))];
      userIds.sort((a, b) => a - b);
      // Map sorted user IDs to corresponding user names
      this.groupChartOptions.labels = userIds.map(userId => {
          const user = this.users.find(user => user.id === userId);
          // return user ? user.name : '';
          return user ? user.name.charAt(0).toUpperCase() + user.name.slice(1) : '';

      });

      // OLD Method not sorting user id
      // this.groupChartOptions.labels = [...new Set(this.bookings.map(booking => {
      //   const user = this.users.find(user => user.id === booking.user_id);
      //   return user? user.name : '';
      // }))];


      // this.groupChartOptions.labels = this.getUserTotals(userName, totalAmount);
      // this.groupChartOptions.labels = this.getUserTotals().map(userTotals.userName => userTotals.totalAmount);
      // this.groupChartOptions.labels = this.bookings.reduce((acc, booking) => ({ ...acc, [booking.user_id]: (acc[booking.user_id] || 0) + booking.total }), {});
      // this.groupChartOptions2.labels = this.bookings.filter(booking => booking.sp === 1).map(booking => group.name);
      this.groupDataLoaded = true;
    });
    // After fetching both users and bookings, update chart labels
    // this.getGroups().then(() => {
    //   this.groupSeries = this.groups.map(group => group.users_count);
    //   this.groupChartOptions.labels = this.groups.map(group => group.name);
    //   this.groupChartOptions2.labels = this.groups.filter(group => group.sp === 1).map(group => group.name);
    //   this.groupDataLoaded = true;
    // });
  },
  computed: {
    canViewBooking() {
      const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
      const hasBookingViewPermission = permissions.includes('booking_view');
      console.log("Can view booking:", hasBookingViewPermission); // 输出是否有权限
      return hasBookingViewPermission;
    },
    canCreateBooking() {
      const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
      const hasBookingCreatePermission = permissions.includes('booking_create');
      console.log("Can create booking:", hasBookingCreatePermission); // 输出是否有权限
      return hasBookingCreatePermission;
    },
    canEditBooking() {
      const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
      const hasBookingEditPermission = permissions.includes('booking_edit');
      console.log("Can edit booking:", hasBookingEditPermission); // 输出是否有权限
      return hasBookingEditPermission;
    },
    canDeleteBooking() {
      const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
      const hasBookingDeletePermission = permissions.includes('booking_delete');
      console.log("Can delete booking:", hasBookingDeletePermission); // 输出是否有权限
      return hasBookingDeletePermission;
    },
  },
  created() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  },
  methods: {
    handleImageError() {
      console.error('Failed to load passport image');
      this.editingPassenger.passport_upload_url = 'path/to/placeholder/image.jpg';
    },
    // handlePassportUpload(e) {
    //     const file = e.target.files[0]; // 获取文件
    //     if (file) {
    //         // 创建文件的 URL 用于预览
    //         const fileURL = URL.createObjectURL(file);
    //         this.editingPassenger.passport_upload_url = fileURL;
    //         // 保存文件对象用于后续上传
    //         this.editingPassenger.passport_upload = file;
    //     } else {
    //         this.editingPassenger.passport_upload_url = '';
    //         this.editingPassenger.passport_upload = null;
    //     }
    // },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handlePassportUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.editingPassenger.passport_upload = file;
        this.editingPassenger.passport_upload_url = URL.createObjectURL(file);
      }
    },

    clearPassportImage() {
      this.editingPassenger.passport_upload = null;
      this.editingPassenger.passport_upload_url = null;
      // 重置文件输入
      this.$refs.fileInput.value = '';
    },

    getFileName(file) {
      if (typeof file === 'string') {
        return file.split('/').pop();
      } else if (file instanceof File) {
        return file.name;
      }
      return '';
    },
    // handlePassportUpload(files) {
    //     const file = files[0]; // 获取第一个文件
    //     if (file) {
    //         // 创建文件的 URL 用于预览
    //         const fileURL = URL.createObjectURL(file);
    //         this.editingPassenger.passport_upload_url = fileURL;
    //         // 保存文件对象用于后续上传
    //         this.editingPassenger.passport_upload = file;
    //     } else {
    //         this.editingPassenger.passport_upload_url = '';
    //         this.editingPassenger.passport_upload = null;
    //     }
    // },
    // beforeDestroy() {
    //     if (this.editingPassenger.passport_upload_url) {
    //         URL.revokeObjectURL(this.editingPassenger.passport_upload_url);
    //     }
    // },
    showBookingsPage() {
      this.currentPage = 'bookings';
    },
    showAddBookingPage() {
      this.currentPage = 'addBooking';
    },
    showEditBookingPage(booking) {
      this.editingBooking = Object.assign({}, booking);
      this.currentPage = 'editBooking';
    },
    getBookings() {
      axios.get('/api/bookings')
        .then(response => {
          this.bookings = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getMyBookings() {
      axios.get(`/api/myBookings/${this.userId}`)
        .then(response => {
          this.myBookings = response.data;
          this.calculateSummary();
        })
        .catch(error => {
          console.log(error);
        });
    },
    calculateSummary() {
      const totalBookings = this.myBookings.length;
      const totalAmount = this.myBookings.reduce((sum, booking) => sum + (booking.total || 0), 0);
      const averageAmount = totalBookings > 0 ? totalAmount / totalBookings : 0;
      const totalPassengers = this.myBookings.reduce((sum, booking) => sum + (booking.passengers?.length || 0), 0);
      const averagePassengers = totalBookings > 0 ? totalPassengers / totalBookings : 0;

      // 计算预订过的不同目的地数量
      const uniqueDestinations = new Set(this.myBookings.map(booking => {
        return booking.tour?.tour_group?.country?.name || 'Unknown';
      }));

      // 计算最受欢迎的目的地
      const destinationCounts = this.myBookings.reduce((acc, booking) => {
        const destination = booking.tour?.tour_group?.country?.name || 'Unknown';
        acc[destination] = (acc[destination] || 0) + 1;
        return acc;
      }, {});
      const mostPopularDestination = Object.entries(destinationCounts).length > 0 
        ? Object.entries(destinationCounts).reduce((a, b) => a[1] > b[1] ? a : b)[0]
        : 'N/A';

      this.summaryData = [
        { metric: 'Total Bookings', value: totalBookings, prefix: '', suffix: '' },
        { metric: 'Total Passengers Booked', value: totalPassengers, prefix: '', suffix: '' },
        // { metric: 'Average Passengers per Booking', value: averagePassengers.toFixed(2), prefix: '', suffix: '' },
        { metric: 'Total Amount', value: this.$formatCurrency(totalAmount), prefix: '', suffix: '' },
        { metric: 'Average Amount per Booking', value: this.$formatCurrency(averageAmount), prefix: '', suffix: '' },
      ];

      // // 按月份统计预订数量
      // const bookingsByMonth = this.myBookings.reduce((acc, booking) => {
      //   const date = new Date(booking.date);
      //   if (date instanceof Date && !isNaN(date)) {
      //     const month = date.getMonth();
      //     acc[month] = (acc[month] || 0) + 1;
      //   }
      //   return acc;
      // }, {});

      // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      // Object.entries(bookingsByMonth).forEach(([month, count]) => {
      //   this.summaryData.push({
      //     metric: `Bookings in ${monthNames[month]}`,
      //     value: count,
      //     prefix: '',
      //     suffix: ''
      //   });
      // });
    },
    getUsers() {
      axios.get('/api/users')
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    addBooking() {
      axios.post('/api/bookings', {
        name: this.name,
      })
      .then(response => {
        console.log(response.data);
        // Redirect to bookings page after successful addition
        this.currentPage = 'bookings';
        this.getBookings();
        // Reset the form fields
        this.name = '';
        this.email = '';
        this.password = '';
      })
      .catch(error => {
        console.log(error);
      });
    },
    editBooking() {
      const bookingData = {
        ...this.editingBooking,
      };
      axios.put(`/api/bookings/${this.editingBooking.id}`, bookingData)
      .then(response => {
        console.log(response.data);
        this.currentPage = 'bookings';
        this.getBookings();
      })
      .catch(error => {
        console.log(error);
      });
    },
    showBookingDetails(booking) {
      this.currentBooking = booking;
      this.currentPage = 'bookingDetails';
    },
    startDeletingBooking(booking) {
    this.itemToDelete = booking;
    this.confirmDelete = true;
    },

    showPassengersPage() {
      this.currentPage = 'passengers';
    },
    showAddPassengerPage() {
      this.currentPage = 'addPassenger';
    },
    showEditPassengerPage(passenger) {
      this.editingPassenger = Object.assign({}, passenger);
      
      // 检查 passenger.passport_upload 是否已经是完整的 URL
      if (passenger.passport_upload && passenger.passport_upload.startsWith('http')) {
        this.editingPassenger.passport_upload_url = passenger.passport_upload;
      } else if (passenger.passport_upload) {
        // 如果不是完整的 URL，则构建完整的 URL
        this.editingPassenger.passport_upload_url = `${process.env.VUE_APP_BASE_URL || ''}${passenger.passport_upload}`;
      } else {
        // 如果没有 passport_upload，设置为空字符串或默认图片
        this.editingPassenger.passport_upload_url = '';
      }
      
      this.currentPage = 'editPassenger';
    },

    getPassengers() {
      axios.get('/api/passengers')
        .then(response => {
          this.passengers = response.data;
          // if (this.passengers.length > 0) {
          //   // 假设我们设置第一个乘客为编辑对象
          //   this.editingPassenger = this.passengers[0];
          // }
          // const baseUrl = process.env.BASE_URL.endsWith('/') ? process.env.BASE_URL : process.env.BASE_URL + '/';
          // this.editingPassenger.passport_upload_url = response.data.passport_upload ? baseUrl + response.data.passport_upload : '';
          // this.editingPassenger = response.data;
          // this.editingPassenger.passport_upload_url = response.data.passport_upload ? process.env.BASE_URL + response.data.passport_upload : '';
          if (this.currentBooking) {
            this.currentBooking.passengers = this.passengers.filter(passenger => passenger.booking_id === this.currentBooking.id);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    addPassenger() {
      axios.post('/api/passengers', {
        booking_id: this.booking_id,
        name: this.name,
        passport: this.passport,
        passport_upload: this.passport_upload,
        designation: this.designation,
        date_of_birth: this.date_of_birth,
        hp: this.hp,
        remark: this.remark,
      })
      .then(response => {
        console.log(response.data);
        // Redirect to passengers page after successful addition
        this.currentPage = 'passengers';
        this.getPassengers();
        this.booking_id = '';
        this.name = '';
        this.passport = '';
        this.passport_upload = '';
        this.designation = '';
        this.date_of_birth = '';
        this.hp = '';
        this.remark = '';
        // Reset the form fields
        this.name = '';
      })
      .catch(error => {
        console.log(error);
      });
    },
    editPassenger() {
      axios.put(`/api/passengers/${this.editingPassenger.id}`, this.editingPassenger)
        .then(response => {
          console.log(response.data);
          // Redirect to passengers page after successful update
          this.editingpassenger = null;
          this.getPassengers();
          this.getBookings();
          this.currentPage = 'bookingDetails';
        })
        .catch(error => {
          console.log(error);
        });
    },
   
    startDeletingPassenger(passenger) {
      this.itemToDelete = passenger;
      this.confirmDelete = true;
    },

    
    confirmDeleteItem() {
      if (this.currentPage === 'bookings') {
        axios.delete(`/api/bookings/${this.itemToDelete.id}`)
          .then(() => {
            // Remove the booking from the bookings array
            this.bookings = this.bookings.filter(u => u.id !== this.itemToDelete.id);
            this.confirmDelete = false;
          })
          .catch(error => {
            console.log(error);
          });
      } else if (this.currentPage === 'passengers') {
        axios.delete(`/api/passengers/${this.itemToDelete.id}`)
          .then(() => {
            // Remove the passenger from the passengers array
            this.passengers = this.passengers.filter(g => g.id !== this.itemToDelete.id);
            this.confirmDelete = false;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    getGroups() {
      return axios.get('/api/groups')
      .then(response => {
        this.groups = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },

    getUserTotals() {
      const userTotals = this.users.map(user => {
        const totalAmount = this.bookings
          .filter(booking => booking.user_id === user.id)
          .reduce((total, booking) => total + booking.total, 0);

        return {
          userName: user.name,
          totalAmount: totalAmount
        };
      });

      return userTotals;
    },

    getUsername_old() {
      return this.bookings.map(booking => {
        const correspondingUser = this.users.find(user => user.id === booking.user_id);
        return correspondingUser ? correspondingUser.name : ''; // Return user name if found, otherwise empty string
      });

      // const userTotals = this.users.map(user => {
      //   const totalAmount = this.bookings
      //     .filter(booking => booking.user_id === user.id)
      //     .reduce((total, booking) => total + booking.total, 0);

      //   return {
      //     userName: user.name,
      //     totalAmount: totalAmount
      //   };
      // });

    },

    // formatCurrency(value) {
    //   return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // },

    formatDate(dateString) {
      const options = { 
        day: '2-digit', 
        month: 'short', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
      };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', options);
    }

  }
};
</script>

<style  scoped>
  .text-right {
    text-align: right;
    padding-right: 10px;
  }
  .text-center {
    text-align: center;
    padding-right: 20px;
  }

</style>