<template>
    <v-container fluid>
      <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Reporting</v-card-title>

      <v-row v-if="currentPage === 'selectReport'" class="text-center pt-4"> <!-- Add class="text-center" to center align all labels -->
        <v-col cols="12" sm="4">
          <v-card @click="currentPage = 'selectTour'" height="100" class="py-4 hoverable-card">
            <label style="font-size:1.2em; color: #00657D; font-weight:bold;"><span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Tour(s) Booking </label>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card @click="currentPage = 'selectFlightTicket'" height="100" class="py-4 hoverable-card">
            <label style="font-size:1.2em; color: #00657D; font-weight:bold;"><span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Flight Ticket(s)</label>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card @click="currentPage = 'selectAgents'" height="100" class="py-4 hoverable-card">
            <label style="font-size:1.2em; color: #00657D; font-weight:bold;"><span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Agent(s)</label>
          </v-card> 
        </v-col>
      </v-row>
      <v-row v-if="currentPage === 'selectReport'" class="text-center">
        <v-col cols="12" sm="4">
          <v-card height="100" class="py-4 disabled-card">
            <label style="font-size:1.2em; color: #9E9E9E; font-weight:bold;">
              <span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Staff(s)
              <br>Under Maintenance
            </label>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card height="100" class="py-4 disabled-card">
            <label style="font-size:1.2em; color: #9E9E9E; font-weight:bold;">
              <span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Companies
              <br>Under Maintenance
            </label>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card height="100" class="py-4 disabled-card">
            <label style="font-size:1.2em; color: #9E9E9E; font-weight:bold;">
              <span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Sales
              <br>Under Maintenance
            </label>
          </v-card> 
        </v-col>
      </v-row>
      <!-- <v-row v-if="currentPage === 'selectReport'" class="text-center">
        <v-col cols="12" sm="4">
          <v-card @click="currentPage = 'selectTour'" height="100" class="py-4 hoverable-card">
            <label style="font-size:1.2em; color: #00657D; font-weight:bold;"><span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Staff(s) </label>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card @click="currentPage = 'selectFlightTicket'" height="100" class="py-4 hoverable-card">
            <label style="font-size:1.2em; color: #00657D; font-weight:bold;"><span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Companies</label>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card @click="currentPage = 'selectTransport'" height="100" class="py-4 hoverable-card">
            <label style="font-size:1.2em; color: #00657D; font-weight:bold;"><span class="material-symbols-outlined" style="font-size: 2em;">picture_as_pdf</span> Sales</label>
          </v-card> 
        </v-col>
      </v-row> -->
      
      <v-card v-if="currentPage === 'selectTour'" class="mx-1 px-2">
        <v-btn @click="currentPage = 'selectReport'" class="my-2 ml-2">Back (返回)</v-btn>
          <!-- <v-select
            v-model="selectedTourId"
            :items="tours"
            item-value="id"
            item-text="code"
            label="选择 Tour"
          ></v-select> -->
          <v-autocomplete
            v-model="selectedTourId"
            :items="tours"
            item-value="id"
            item-text="code"
            label="选择 Tour"
            clearable
            autocomplete="off"
            :filter="customFilter"
            :search-input.sync="search"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.code"></v-list-item-title>
                <v-list-item-subtitle v-text="item.tour_group.tour_group_name"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-btn color="primary" @click="fetchReportDataForSelectedTour">生成报告</v-btn>
          <div id="report-content" v-if="reportDataLoaded">
            <br />
            <h1>配套: {{ tour_group.tour_group_name }}</h1>
            <p>配套华语: {{ tour_group.tour_group_name_chinese }}<br />
              国家: {{ tour_group.country.name}}<br />
              配套编号: {{ code }}<br />
              预订总数: {{ bookings.length }}<br />
              乘客总数: {{ bookings.reduce((total, booking) => total + booking.passengers.length, 0) }}<br />
              Total (总计): <b>{{ $formatCurrency(bookings.reduce((total, booking) => total + booking.total, 0)) }}</b><br />
              Average (平均每位乘客): {{ $formatCurrency(bookings.reduce((total, booking) => total + booking.total, 0) / bookings.length) }}
              <!-- Total (总计): RM {{ bookings.reduce((total, booking) => total + booking.total, 0).toFixed(2) }}<br />
              Average (平均每位乘客): RM {{ (bookings.reduce((total, booking) => total + booking.total, 0) / bookings.length).toFixed(2) }} -->
            </p>
            
            <h4>Flight Info (航班票信息)</h4>
            <p>Airline (航空公司): {{ airline }}<br />
              Departure Date (出发日期): {{ departure_date }}<br />
              Return Date (返回日期): {{ return_date }}
            </p>
            <ul>
              <li v-for="ticket in flight_tickets" :key="ticket.id">
                航班号: {{ ticket.pnr }},
                航空公司: {{ ticket.airline }},
                出发日期: {{ ticket.departure_date }},
                返回日期: {{ ticket.return_date }},
                出发地: {{ ticket.from }},
                目的地: {{ ticket.to }},
                座位号: {{ ticket.seat }}
              </li>
            </ul>
            <h4>Booking Info (预订信息)</h4>
            <ol v-if="bookings.length > 0">
              <li v-for="booking in bookings" :key="booking.id" style="padding-top:20px">
                预订: {{ booking.id }}, 日期: {{ booking.date }}, 总计: <b> {{ $formatCurrency(booking.total) }}</b> ({{booking.passengers.length}} Pax)
                <h6>Passenger(s) Info (乘客信息)</h6>
                <ol type="a">
                  <li v-for="passenger in booking.passengers" :key="passenger.id">
                    <span class="pl-1">名字: <b>{{ passenger.name }}</b></span>
                    <span class="pl-5">护照号: {{ passenger.passport }} </span>
                    <span class="pl-5">出生日期: {{ passenger.date_of_birth }} </span>
                    <span class="pl-5">联系方式: {{ passenger.hp }} </span>
                    <span class="pl-5">备注: {{ passenger.remark }} </span>
                  </li>
                  <!-- <li v-for="passenger in booking.passengers" :key="passenger.id" style="padding-bottom:10px">
                    <span class="pl-1">名字: [ <b>{{ passenger.name }}</b> ]</span>
                    <span class="pl-5">护照号: [ {{ passenger.passport }} ] </span>
                    <span class="pl-5">出生日期: [ {{ passenger.date_of_birth }} ] </span>
                    <span class="pl-5">联系方式: [ {{ passenger.hp }} ] </span>
                    <span class="pl-5">备注: [ {{ passenger.remark }} ] </span>
                  </li> -->
                  <!-- <li v-for="passenger in booking.passengers" :key="passenger.id" style="padding-bottom:10px">
                    名字: <b>{{ passenger.name }}</b>,
                    护照号: {{ passenger.passport }},
                    出生日期: {{ passenger.date_of_birth }},
                    联系方式: {{ passenger.hp }},
                    备注: {{ passenger.remark }}
                  </li> -->
                </ol>
              </li>
            </ol>
            <ol v-else>No Record Found.</ol>
            <br />
          
        </div>
        <div v-else>
          <p></p>
        </div>
        <v-btn class="fixed-bottom" v-if="bookings.length > 0" color="primary" @click="generatePDF">导出PDF</v-btn>
      </v-card>
      <v-card v-if="currentPage === 'selectFlightTicket'">
        <v-card-title>Flight Ticket Report (航班票据报告)</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="pnr"
            label="Enter PNR (输入 PNR)"
            outlined
          ></v-text-field>
          <v-btn color="primary" @click="generateFlightTicketReport">
            Generate Report (生成报告)
          </v-btn>
        </v-card-text>
        <v-btn @click="currentPage = 'selectReport'" class="my-2 ml-2">Back (返回)</v-btn>
      </v-card>

      <v-card v-if="currentPage === 'flightTicketReport'">
        <v-card-title>Flight Ticket Report (航班票据报告)
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" @click="printReport">Print Report (打印报告)</v-btn> -->
      </v-card-title>
        <v-card-text ref="reportContent">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>PNR</th>
                <th>Airline (航空公司)</th>
                <th>Departure (出发)</th>
                <th>Return (返回)</th>
                <th>From (从)</th>
                <th>To (到)</th>
                <th>Seat (座位)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="flightTicket">
                <td>{{ flightTicket.pnr }}</td>
                <td>{{ flightTicket.airline }}</td>
                <td>{{ formatDate(flightTicket.departure_date) }}</td>
                <td>{{ formatDate(flightTicket.return_date) }}</td>
                <td>{{ flightTicket.from }}</td>
                <td>{{ flightTicket.to }}</td>
                <td>{{ flightTicket.seat }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <h3 class="mt-4">Passengers (乘客)</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Name (姓名)</th>
                  <th>Passport (护照)</th>
                  <th>Date of Birth (出生日期)</th>
                  <th>Phone (电话)</th>
                  <th>Remark (备注)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="passenger in passengers" :key="passenger.id">
                  <td>{{ passenger.name }}</td>
                  <td>{{ passenger.passport }}</td>
                  <td>{{ formatDate(passenger.date_of_birth) }}</td>
                  <td>{{ passenger.hp }}</td>
                  <td>{{ passenger.remark }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-btn color="primary" @click="printReport">Print Report (打印报告)</v-btn>
        <v-btn @click="currentPage = 'selectFlightTicket'" class="my-2 ml-2">Back (返回)</v-btn>
      </v-card>

      <v-card v-if="currentPage === 'selectAgents'">
        <v-card-title>Agent Report (代理报告)</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedAgent"
            :items="agents"
            item-text="name"
            item-value="id"
            label="Select an Agent"
            @change="fetchAgentBookings"
          ></v-autocomplete>
        </v-card-text>
      </v-card>

      <v-card v-if="currentPage === 'agentReport'">
        <v-card-title>Agent Report for {{ selectedAgentName }}
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="printAgentReport">Print Report</v-btn>
        </v-card-title>
        <v-card-text ref="reportContent">
          <v-data-table
            :headers="bookingHeaders"
            :items="agentBookings"
            :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          >
            <!-- 自定义列显示 -->
            <template v-slot:item.date="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ $formatCurrency(item.total) }}
            </template>
            <template v-slot:item.passengers="{ item }">
              {{ item.passengers.length }}
            </template>
            <template v-slot:item.tour="{ item }">
              {{ item.tour.tour_group.tour_group_name }}
            </template>
          </v-data-table>
          <!-- 统计信息 -->
          <v-card class="mt-4">
            <v-card-title>Summary</v-card-title>
            <v-card-text>
              <p>Total Bookings: {{ agentBookings.length }}</p>
              <p>Total Revenue: {{ $formatCurrency(totalRevenue) }}</p>
              <p>Total Passengers: {{ totalPassengers }}</p>
              <p>Average Booking Value: {{ $formatCurrency(averageBookingValue) }}</p>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card v-if="currentPage === 'selectTransport'">
        <v-btn @click="currentPage = 'selectReport'" class="my-2 ml-2">Back (返回)</v-btn>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';
  export default {
    data() {
      return {
        reportDataLoaded: false,
        currentPage: 'selectReport',
        tours: [], // 存储所有 Tour 的列表
        selectedTourId: null, // 存储选中的 Tour ID
        search: null,
        package_name: '',
        departure_date: '',
        return_date: '',
        airline: '',
        bookings: [],
        country: [],
        flight_tickets: [],
        tour_group: [],
        package_name_chinese: '',
        code: '',
        pnr: '',
        flightTicket: null,
        passengers: [],
        selectedAgent: null,
        agents: [],
        agentBookings: [],
        bookingHeaders: [
          { text: 'Date', value: 'date' },
          { text: 'Tour', value: 'tour' },
          { text: 'Passengers', value: 'passengers' },
          { text: 'Total', value: 'total' },
          // { text: 'Status', value: 'status' }
        ],
      };
    },created() {
      this.fetchTours();
    },
    computed: {
      selectedAgentName() {
        const agent = this.agents.find(a => a.id === this.selectedAgent);
        return agent ? agent.name : '';
      },
      totalRevenue() {
        return this.agentBookings.reduce((sum, booking) => sum + booking.total, 0);
      },
      totalPassengers() {
        return this.agentBookings.reduce((sum, booking) => sum + booking.passengers.length, 0);
      },
      averageBookingValue() {
        return this.agentBookings.length > 0 ? this.totalRevenue / this.agentBookings.length : 0;
      },
      userId() {
        return localStorage.getItem('user_id');
      }
    },
    mounted() {
      this.fetchAgents();
    },
    methods: {
      customFilter(item, queryText, itemText) {
        const searchText = queryText.toLowerCase();
        return item.code.toLowerCase().indexOf(searchText) > -1 ||
              item.tour_group.tour_group_name.toLowerCase().indexOf(searchText) > -1;
      },
      printAgentReport() {
        const element = this.$refs.reportContent;
        html2canvas(element).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(`agent_report_${this.selectedAgentName}.pdf`);
        });
      },
      fetchAgents() {
        axios.get('/api/agents')
          .then(response => {
            this.agents = response.data.filter(user => user.roles.some(role => role.name === 'agent'));
          })
          .catch(error => {
            console.error('Error fetching agents:', error);
          });
      },
      fetchAgentBookings() {
        if (!this.selectedAgent) return;
        
        axios.get(`/api/bookings?user_id=${this.selectedAgent}`)
          .then(response => {
            this.agentBookings = response.data;
            this.currentPage = 'agentReport';
          })
          .catch(error => {
            console.error('Error fetching agent bookings:', error);
          });
      },
      selectReportType(type) {
        this.selectedReportType = type;
      },
      async generatePDF() 
      {
        const headerImg = new Image();
        headerImg.src = '/images/esplanad-logo-small.png';  // Adjust the path as necessary
        await new Promise((resolve) => {
            headerImg.onload = resolve;
        });

        const element = document.getElementById('report-content');
        const canvas = await html2canvas(element, { scale: 1 });
        const contentData = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: 'portrait',
            compress: true,
            format : 'a4',
            putOnlyUsedFonts:true
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const headerHeight = 16; // Height of the header image
        pdf.addImage(headerImg.src, 'PNG', 90, 2, pageWidth * 0.15, headerHeight * 0.70);

        const imgProperties = pdf.getImageProperties(contentData);
        const leftMargin = 4;
        const contentWidth = pageWidth - 2 * leftMargin;
        const scaledHeight = imgProperties.height * (contentWidth / imgProperties.width);

        let contentRemaining = scaledHeight;
        let position = 0;  // Track the position on the canvas from which we start drawing

        while (contentRemaining > 0) {
            const topMargin = headerHeight + 2;
            // const heightToDraw = Math.min(pageHeight - topMargin, contentRemaining); // either draw the full page or the remainder
            const heightToDraw = Math.min(pageHeight, contentRemaining); // not required topmargin for remainder
            pdf.addImage(contentData, 'PNG', leftMargin, topMargin - position, contentWidth, scaledHeight, undefined, 'FAST');

            contentRemaining -= heightToDraw;  // Reduce the remaining content height by the amount just drawn
            position += heightToDraw;  // Increase the canvas position

            if (contentRemaining > 0) {
                pdf.addPage();
                // Re-add the header image to each new page
                pdf.addImage(headerImg.src, 'PNG', 90, 2, pageWidth * 0.15, headerHeight * 0.70);
            }
        }

        pdf.save('tour-report.pdf');
      },

      async generatePDF_missing_leftMargin() 
      {
        const headerImg = new Image();
        headerImg.src = '/images/esplanad-logo-small.png';  // Adjust the path as necessary
        await new Promise((resolve) => {
            headerImg.onload = resolve;
        });

        const element = document.getElementById('report-content');
        const canvas = await html2canvas(element, { scale: 1 });
        const contentData = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: 'portrait',
            compress: true,
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const headerHeight = 16; // Height of the header image
        pdf.addImage(headerImg.src, 'PNG', 90, 2, pageWidth * 0.15, headerHeight * 0.70);

        const imgProperties = pdf.getImageProperties(contentData);
        const contentWidth = pageWidth;
        const scaledHeight = imgProperties.height * (contentWidth / imgProperties.width);

        let contentRemaining = scaledHeight;
        let position = 0;  // Track the position on the canvas from which we start drawing

        while (contentRemaining > 0) {
            const topMargin = headerHeight + 2;
            const heightToDraw = Math.min(pageHeight - topMargin, contentRemaining); // either draw the full page or the remainder
            pdf.addImage(contentData, 'PNG', 0, topMargin - position, contentWidth, scaledHeight, undefined, 'FAST');

            contentRemaining -= heightToDraw;  // Reduce the remaining content height by the amount just drawn
            position += heightToDraw;  // Increase the canvas position

            if (contentRemaining > 0) {
                pdf.addPage();
                // Re-add the header image to each new page
                pdf.addImage(headerImg.src, 'PNG', 90, 2, pageWidth * 0.15, headerHeight * 0.70);
            }
        }

        pdf.save('tour-report.pdf');
      },

      async generatePDF_Working_But_1Page() {
        // Load the header image
        const headerImg = new Image();
        headerImg.src = '/images/esplanad-logo-small.png';  // Adjust the path as necessary
        await new Promise((resolve) => {
          headerImg.onload = resolve;
        });

        // Capture the content of the element as a canvas
        const element = document.getElementById('report-content');
        const canvas = await html2canvas(element, { scale: 1 });
        const contentData = canvas.toDataURL('image/png');

        // Create a new jsPDF instance
        const pdf = new jsPDF({
          orientation: 'portrait',
          compress: true,
        });

        // Add the header image to the PDF
        const pageWidth = pdf.internal.pageSize.getWidth();
        const headerHeight = 16; // Set the height of your header image; adjust as needed
        pdf.addImage(headerImg.src, 'PNG', 90, 2, pageWidth * 0.15, headerHeight * 0.70 );

        // Calculate position and scaling for the content
        const imgProperties = pdf.getImageProperties(contentData);
        const contentWidth = pdf.internal.pageSize.getWidth();
        const contentHeight = (imgProperties.height * contentWidth) / imgProperties.width;
        const leftMargin = 4;
        const topMargin = headerHeight + 2; // Adjust so content starts right below the header

        // Add main content image to PDF, adjust dimensions to fit the page
        pdf.addImage(contentData, 'PNG', leftMargin, topMargin, contentWidth - (2 * leftMargin), contentHeight);

        // Save the PDF
        pdf.save('tour-report.pdf');
      },

      async generatePDF_OLD() {
        const element = document.getElementById('report-content');
        // const canvas = await html2canvas(element);
        const canvas = await html2canvas(element, {
          scale: 1 // Adjust scale to reduce resolution; tweak as necessary
        });
        const data = canvas.toDataURL('image/png');

        // const pdf = new jsPDF('p', 'pt','a4', true);
        const pdf = new jsPDF({
          orientation: 'portrait',
          compress: true,

        });
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        const leftMargin = 4; // Adjust as needed
        const topMargin = 2; // Adjust as needed

        // pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // Add image to PDF
        // pdf.addImage(data, 'JPEG', 0, 0, pdfWidth, pdfHeight); // Use JPEG here
        
        // Add image to PDF with left margin
        // pdf.addImage(data, 'JPEG', leftMargin, topMargin, pdfWidth - (2 * leftMargin), pdfHeight);
        pdf.addImage(data, 'PNG', leftMargin, topMargin, pdfWidth - (2 * leftMargin), pdfHeight);
        
        pdf.save('tour-report.pdf');
      },

      async fetchTours() {
        try {
          const response = await axios.get('/api/tours'); // 假设这是获取所有 Tours 的 API 路径
          this.tours = response.data;
        } catch (error) {
          console.error('Failed to fetch tours:', error);
        }
      },
      async fetchReportDataForSelectedTour() {
        if (!this.selectedTourId) {
          alert("请选择一个 Tour。");
          return; // 如果没有选中的 Tour，直接返回
        }

        try {
          const response = await axios.get(`/api/tours/${this.selectedTourId}/report`);
          // 假设response.data直接包含了Tour的详细信息
          this.package_name = response.data.package_name;
          this.departure_date = response.data.departure_date;
          this.return_date = response.data.return_date;
          this.airline = response.data.airline;
          this.bookings = response.data.bookings;
          // this.country = response.data.country;
          // this.package_name_chinese = response.data.package_name_chinese;
          this.tour_group = response.data.tour_group;
          this.code = response.data.code;
          this.flight_tickets = response.data.flight_tickets;
          this.reportDataLoaded = true;
        } catch (error) {
          console.error('Failed to fetch report data:', error);
        }
      },

      generateFlightTicketReport() {
      // 调用 API 获取航班票据信息
      axios.get(`/api/flight-tickets/${this.pnr}`)
        .then(response => {
          this.flightTicket = response.data.flightTicket;
          this.passengers = response.data.passengers;
          this.currentPage = 'flightTicketReport';
        })
        .catch(error => {
          console.error('Error fetching flight ticket data:', error);
          // 处理错误，例如显示错误消息
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    },
    printReport() {
      const element = this.$refs.reportContent;
      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`flight_ticket_report_${this.flightTicket.pnr}.pdf`);
      });
    },
  },
  };
  </script>
  <style scoped>
    .fixed-bottom {
      position: fixed;
      bottom: 10px; /* Distance from the bottom of the viewport */
      left: 45%; /* Distance from the right of the viewport, adjust as necessary */
      z-index: 1000; /* Ensure it's above other content */
    }
    .hoverable-card:hover {
      transition: 0.3s;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); /* Apply elevation effect on hover */
      background-color: #FFF00030;
    }
    @media print {
      .v-btn {
        display: none !important;
      }
      .v-card {
        box-shadow: none !important;
      }
    }
    .disabled-card {
      opacity: 0.6;
      cursor: not-allowed;
      background-color: #F5F5F5;
    }

    .disabled-card:hover {
      box-shadow: none !important;
    }

    .disabled-card label {
      cursor: not-allowed;
    }
  </style>