<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2" class="bg-light-blue">
        <v-card>
          <v-list>
            <v-list-item @click="currentPage = 'dashboard'">
              <v-list-item-action>
                <!-- <v-icon>mdi-view-dashboard</v-icon> -->
                <span class="material-symbols-outlined">dashboard </span>

              </v-list-item-action>
              <!-- <span class="material-icons pr-1">face</span> -->
              <v-list-item-content :class="{ 'active': currentPage === 'dashboard' }">Dashboard</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="canViewCountry" @click="currentPage = 'countries'">
              <v-list-item-action>
                <!-- <v-icon>mdi-account-country</v-icon> -->
                <span class="material-symbols-outlined">language </span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'countries' }">Countries</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="canViewFlightTicket" @click="currentPage = 'flight_tickets'">
              <v-list-item-action>
                <!-- <v-icon>mdi-account-flight_ticket</v-icon> -->
                <!-- <span class="material-symbols-outlined">language </span> -->
                <span class="material-symbols-outlined">flightsmode</span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'flight_tickets' }">Flight Tickets</v-list-item-content>
            </v-list-item>

            <!-- <v-list-item v-if="canViewTourPackage"> -->
            <v-list-item @click="currentPage = 'tour_packages'">
              <v-list-item-action>
                <span class="material-symbols-outlined">package</span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'tour_packages' }">Tour Package</v-list-item-content>
            </v-list-item>

            <v-list-item v-if="canViewTour" @click="currentPage = 'tours'">
              <v-list-item-action>
                <!-- <v-icon>mdi-account-tour</v-icon> -->
                <span class="material-symbols-outlined">tour</span>
              </v-list-item-action>
              <v-list-item-content :class="{ 'active': currentPage === 'tours' }">Tours</v-list-item-content>
            </v-list-item>
            <!-- Add more items here -->
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card v-if="currentPage === 'dashboard'">
          <!-- <v-card-title>Dashboard</v-card-title> -->
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Dashboard</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card-title class="justify-center"><h6>Total Booking (Unpaid)</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="donut" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 5 content here -->
                <v-card-title class="justify-center"><h6>Polar Total Booking</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="polarArea" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 6 content here -->
                <v-card-title class="justify-center"><h6>Another Booking Group</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="pie" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-card-title class="justify-center"><h6>Total Booking By Tour</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="donut" :options="groupChartOptions4" :series="groupSeries4"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 5 content here -->
                <v-card-title class="justify-center"><h6>Polar User Group</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="polarArea" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!-- Grid 6 content here -->
                <v-card-title class="justify-center"><h6>User Group with SP</h6></v-card-title>
                <apexchart v-if="groupDataLoaded" type="donut" :options="groupChartOptions" :series="groupSeries"></apexchart>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'countries'">
          <!-- <v-card-title>Countries</v-card-title> -->
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Countries</v-card-title>
          <v-card-text>
            <v-data-table :headers="countryHeaders" :items="countries" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.img_url="{ item }">
                  <img :src="item.img_url" style="width: 30px; height: 30px; border-radius: 50%; border: 1px solid #CCCCCC;"
                    :title="item.name" />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="canEditCountry" small color="blue darken-1" text @click="showEditCountryPage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
                <v-btn v-if="canDeleteCountry" small color="red darken-1" text @click="startDeletingCountry(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
            <v-btn @click="currentPage = 'addCountry'">Add Country</v-btn>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'addCountry'">
          <v-card-title>Add Country</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addCountry">
              <v-text-field label="Name" type="text" v-model="name" required></v-text-field>
              <v-row>
                <v-col>
                  <v-file-input
                    ref="fileInput"
                    label="Flag"
                    v-model="img_url"
                    required
                    @change="onCountryFileChange"
                    :show-size="false"
                    :hide-details="true"
                    :hide-input="true"
                    style="display:none"
                  ></v-file-input>
                  <v-btn color="primary" @click.prevent="triggerFileSelect">Select File</v-btn>
                  <!-- <v-img :src="img_url" style="width: 30px; height: 30px; border-radius: 50%; border: 1px solid #CCCCCC;" :title="name" /> -->
                </v-col>
              </v-row>
              <!-- <v-file-input label="Flag" v-model="img_url" required @change="onCountryFileChange" ></v-file-input> -->
              <v-btn @click="currentPage = 'countries'">Back</v-btn>
              <v-btn type="submit">Add Country</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'editCountry'">
          <v-card-title>Edit Country</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="editCountry">
              <v-text-field label="Name" type="text" v-model="editingCountry.name" required></v-text-field>
              <v-btn @click="uploadCountryImage">Upload</v-btn>
              <v-btn @click="editingCountry.img_url = null">Clear</v-btn>
              <v-file-input
                label="Flag"
                clearable
                @change="onCountryFileChange"
              ></v-file-input>
              <v-img v-if="editingCountry.img_url" :src="typeof editingCountry.img_url === 'string' ? editingCountry.img_url : URL.createObjectURL(editingCountry.img_url)" style="width: 30px; height: 30px; border-radius: 50%; border: 1px solid #CCCCCC;"
                :title="editingCountry.name" />
              <v-btn @click="currentPage = 'countries'">Cancel</v-btn>
              <v-btn type="submit">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'flight_tickets'">
          <!-- <v-card-title>Flight Tickets</v-card-title> -->
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Flight Tickets (PNR)</v-card-title>
          <v-card-text>
            <v-text-field v-model="searchText" label="Search" single-line hide-details></v-text-field>
            <flat-pickr
              :modelValue="searchDate"
              :config="dateConfigSearch"
              @update:modelValue="value => searchDate = value"
              placeholder="Search by departure date"
            ></flat-pickr>
            <v-btn small color="blue" v-if="searchDate" @click="searchDate = null">Clear</v-btn>
            <v-data-table :headers="flight_ticketHeaders" :items="filteredFlightTickets" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.departure_date="{ item }">
                {{ $formatDate(item.departure_date) }}
              </template>
              <template v-slot:item.return_date="{ item }">
                {{ $formatDate(item.return_date) }}
              </template>
              <template v-slot:header.icon={item} v-slot:item.icon="{ item }">
                <v-icon>flight_takeoff</v-icon>
              </template>
              <template v-slot:item.icon="{ item }">
                <span class="material-symbols-outlined" title="Round trip">swap_horiz</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="canEditFlightTicket" small color="blue darken-1" text @click="showEditFlightTicketPage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
                <v-btn v-if="canDeleteFlightTicket" small color="red darken-1" text @click="startDeletingFlightTicket(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
            <v-btn @click="currentPage = 'addShowFlightTicket'">Add Flight Ticket</v-btn>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'addShowFlightTicket'">
          <v-card-title>Add Flight Ticket</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="submitFlightTicket">
              <v-text-field label="PNR" type="text" v-model="addFlightTicket.pnr" required></v-text-field>
              <v-select
                label="Airline"
                v-model="selectedAirline"
                :items="airlines"
                item-text="name"
                item-value="code"
                @change="checkIfOtherSelected"
                required
              ></v-select>
              <v-text-field
                v-if="selectedAirline && selectedAirline === '1'"
                label="Enter Airline Name"
                v-model="customAirline"
                @input="checkIfOtherSelected"
                required
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field label="Departure Date" type="datetime-local" v-model="addFlightTicket.departure_date" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Return Date" type="datetime-local" v-model="addFlightTicket.return_date" required></v-text-field>
                </v-col>
              </v-row>
              <!-- <label class="my-label">Departure Date</label>
              <flat-pickr
                :modelValue="departure_date"
                @update:modelValue="value => departure_date = value"
                :config="dateConfig"
              ></flat-pickr>
              <label class="my-label">Return Date</label>
              <flat-pickr
                :modelValue="return_date"
                @update:modelValue="value => return_date = value"
                :config="dateConfig"
              ></flat-pickr> -->
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="From"
                    v-model="selectedFrom"
                    :items="airports"
                    :item-text="airportText"
                    :filter="customFilter"
                    item-value="IATA"
                    return-object
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="To"
                    v-model="selectedTo"
                    :items="airports"
                    :item-text="airportText"
                    :filter="customFilter"
                    item-value="IATA"
                    return-object
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-text-field label="Seat" type="text" v-model="addFlightTicket.seat" required></v-text-field>
              <v-btn @click="currentPage = 'flight_tickets'">Back</v-btn>
              <v-btn type="submit">Add FlightTicket</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'editFlightTicket'">
          <v-card-title>Edit FlightTicket</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="editFlightTicket">
              <v-text-field label="PNR" type="text" v-model="editingFlightTicket.pnr" required></v-text-field>
              <v-text-field label="Airline" type="text" v-model="editingFlightTicket.airline" required></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field label="Departure Date" type="datetime-local" v-model="editingFlightTicket.departure_date" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Return Date" type="datetime-local" v-model="editingFlightTicket.return_date" required></v-text-field>
                </v-col>
              </v-row>
              <!-- <label class="my-label">Departure Date</label>
              <flat-pickr
                :modelValue="editingFlightTicket.departure_date"
                @update:modelValue="value => editingFlightTicket.departure_date = value"
                :config="dateConfig"
              ></flat-pickr>
              <label class="my-label">Return Date</label>
              <flat-pickr
                :modelValue="editingFlightTicket.return_date"
                @update:modelValue="value => editingFlightTicket.return_date = value"
                :config="dateConfig"
              ></flat-pickr> -->
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="From"
                    v-model="editingFlightTicket.from"
                    :items="airports"
                    :item-text="airportText"
                    :filter="customFilter"
                    item-value="IATA"
                    return-object
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="To"
                    v-model="editingFlightTicket.to"
                    :items="airports"
                    :item-text="airportText"
                    :filter="customFilter"
                    item-value="IATA"
                    return-object
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-text-field label="Seat" type="text" v-model="editingFlightTicket.seat" required></v-text-field>
              <v-btn @click="currentPage = 'flight_tickets'">Cancel</v-btn>
              <v-btn type="submit">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'tour_packages'">
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Tour Packages</v-card-title>
          <v-card-text>
            <v-data-table :headers="tourPackageHeaders" :items="tour_groups" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">

              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn small color="green darken-1" text @click="showEditTourPackagePage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
                <v-btn small color="red darken-1" text @click="startDeletingTourPackage(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
            <v-btn @click="currentPage = 'addTourPackage'">Add Tour Package</v-btn>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'addTourPackage'">
          <v-card-title>Add Tour Package</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addTourPackage">
              <v-select label="Country" :items="countries" item-text="name" item-value="id" v-model="country_id" required></v-select>
              <v-text-field label="Package Name" type="text" v-model="tour_group_name" required></v-text-field>
              <v-text-field label="Package Name (Chinese)" type="text" v-model="tour_group_name_chinese" required></v-text-field>
              <v-file-input ref="fileInput" label="Tour Image" v-model="tour_img_url" @change="onFileChange" :show-size="false" :hide-details="true" :hide-input="true" style="display:none"></v-file-input>
              <v-btn color="primary" @click.prevent="triggerFileSelect">Select File</v-btn>
              <v-btn @click="currentPage = 'tour_packages'">Back</v-btn>
              <v-btn type="submit">Add Tour Package</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'editTourPackage'">
          <v-card-title>Edit Tour Package</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="editTourPackage">
              <v-select label="Country" :items="countries" item-text="name" item-value="id" v-model="editingTourPackage.country_id" required></v-select>
              <v-text-field label="Package Name" type="text" v-model="editingTourPackage.tour_group_name" required></v-text-field>
              <v-text-field label="Package Name (Chinese)" type="text" v-model="editingTourPackage.tour_group_name_chinese" required></v-text-field>
              <v-file-input ref="fileInput" label="Tour Image" v-model="editingTourPackage.tour_img_url" @change="onFileChange" :show-size="false" :hide-details="true" :hide-input="true" style="display:none"></v-file-input>
              <v-btn color="primary" @click.prevent="triggerFileSelect">Select File</v-btn>
              <v-btn @click="currentPage = 'tour_packages'">Back</v-btn>
              <v-btn type="submit">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'tours'">
          <!-- <v-card-title>Tours</v-card-title> -->
          <v-card-title class="py-2 px-4" style="background-color: bisque; width: 100%; padding-left: 28px; font-size: 14px; font-weight: bold">Tours</v-card-title>
          <v-card-text>
            <v-text-field v-model="searchPackageName" label="Search by package name"></v-text-field>
            <flat-pickr
              :modelValue="searchDepartureDate"
              :config="dateConfigSearch"
              @update:modelValue="value => searchDepartureDate = value"
              placeholder="Search by departure date"
            ></flat-pickr>
            <v-btn small color="blue" v-if="searchDepartureDate" @click="searchDepartureDate = ''">Clear</v-btn>
            <v-text-field v-model="searchAirline" label="Search by airline"></v-text-field>
            <v-data-table :headers="tourHeaders" :items="filteredTours" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.package_name="{ item }">
                  <span class="text-uppercase" style="color: #00657D; font-size: 0.9em; font-weight: bold;">{{ item.package_name }}</span>
              </template>
              <template v-slot:item.departure_date="{ item }">
                {{ $formatDate(item.departure_date) }}
              </template>
              <!-- <template v-slot:item.remark="{ item }">
                <div style="max-width: 360px;">
                  {{ item.remark }}
                </div>
              </template> -->
              <template v-slot:item.remark="{ item }">
                <div style="max-width: 360px;" v-html="item.remark">
                  <!-- HTML content of item.remark will be rendered here -->
                </div>
              </template>

              <template v-slot:item.group="{ item }">
                {{ item.groups?.map(group => group.name).join(', ') }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn small color="green darken-1" text @click="showViewTourPage(item)">
                  <v-icon small>mdi-eye</v-icon>
                  View
                </v-btn>
                <!-- <v-btn small color="blue darken-1" text @click="showEditTourPage(item)">
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn> -->
                <v-btn v-if="canDeleteTour" small color="red darken-1" text @click="startDeletingTour(item)">
                  <v-icon small>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </template>
            </v-data-table>
            <v-btn @click="currentPage = 'addTour'">Add Tour</v-btn>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'viewTour'">
          <v-card-title>{{ viewingTour.tour_group.tour_group_name }}</v-card-title>
          <v-card-text>
            <p><strong>Package Name (Chinese):</strong> {{ viewingTour.tour_group.tour_group_name_chinese }}</p>
            <p><strong>Airline:</strong> {{ viewingTour.airline }}</p>
            <p><strong>Tour Code:</strong> {{ viewingTour.code }}</p>
            <p><strong>Departure Date:</strong> {{ viewingTour.departure_date }}</p>
            <p><strong>Return Date:</strong> {{ viewingTour.return_date }}</p>
            <p><strong>Special Price:</strong> {{ viewingTour.sp }}</p>
            <p><strong>Tier 1 Price:</strong> {{ viewingTour.tier1 }}</p>
            <p><strong>Tier 2 Price:</strong> {{ viewingTour.tier2 }}</p>
            <p><strong>Tier 3 Price:</strong> {{ viewingTour.tier3 }}</p>
            <p><strong>Special Price (Commission):</strong> {{ viewingTour.sp }}</p>
            <p><strong>Tier 1 Price (Commission):</strong> {{ viewingTour.tier1_c }}</p>
            <p><strong>Tier 2 Price (Commission):</strong> {{ viewingTour.tier2_c }}</p>
            <p><strong>Tier 3 Price (Commission):</strong> {{ viewingTour.tier3_c }}</p>
            <p><strong>Country:</strong> {{ viewingTour.country ? viewingTour.country.name : '' }}</p>
            <p><strong>Minimum Group Size:</strong> {{ viewingTour.min_g }}</p>
            <p><strong>Flight Tickets:</strong> {{ viewingTour.flight_tickets.map(ticket => ticket.pnr).join(', ') }}</p>
            <p><strong>Total Seats:</strong> {{ viewingTour.flight_tickets.reduce((total, ticket) => total + Number(ticket.seat), 0) }}</p>
            <!-- <p><strong>Total Seats1:</strong> {{ Array.isArray(viewingTour.flight_tickets) ? viewingTour.flight_tickets.reduce((total, ticket) => total + Number(ticket.seat), 0) : 0 }}</p> -->
            <p><strong>Remark:</strong> {{ viewingTour.remark }}</p>
            <v-btn @click="currentPage = 'tours'">Back</v-btn>
            <v-btn v-if="canEditTour" @click="showEditTourPage(viewingTour)">Edit</v-btn>
            <v-btn v-if="canEditTour" @click="showDuplicateTourPage(viewingTour)">Duplicate</v-btn>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'addTour'">
          <v-card-title>Add Tour</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addTour">
              <v-select
                label="Tour Group"
                :items="tour_groups"
                item-text="tour_group_name"
                item-value="id"
                v-model="tour_group_id"
                required
              ></v-select>
              <v-text-field label="Airline" type="text" v-model="airline" required></v-text-field>
              <v-text-field label="Tour Code" type="text" v-model="code" required></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field label="Departure Date" type="datetime-local" v-model="departure_date" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Return Date" type="datetime-local" v-model="return_date" required></v-text-field>
                </v-col>
              </v-row>
              <!-- <label class="my-label">Departure Date</label>
              <flat-pickr
                :modelValue="departure_date"
                @update:modelValue="value => departure_date = value"
                :config="dateConfig"
              ></flat-pickr>
              <label class="my-label">Return Date</label>
              <flat-pickr
                :modelValue="return_date"
                @update:modelValue="value => return_date = value"
                :config="dateConfig"
              ></flat-pickr> -->
              <v-text-field label="Special Price" type="text" v-model="sp"></v-text-field>
              <v-text-field label="Tier 1 Price" type="text" v-model="tier1"></v-text-field>
              <v-text-field label="Tier 2 Price" type="text" v-model="tier2"></v-text-field>
              <v-text-field label="Tier 3 Price" type="text" v-model="tier3" required></v-text-field>
              <v-text-field label="Special Price (Commission)" type="text" v-model="sp_c"></v-text-field>
              <v-text-field label="Tier 1 Price (Commission)" type="text" v-model="tier1_c"></v-text-field>
              <v-text-field label="Tier 2 Price (Commission)" type="text" v-model="tier2_c"></v-text-field>
              <v-text-field label="Tier 3 Price (Commission)" type="text" v-model="tier3_c" required></v-text-field>
              <v-select
                label="Country"
                :items="countries"
                item-text="name"
                item-value="id"
                v-model="country_id"
                required
              ></v-select>
              <v-text-field label="Minimum Group Size" type="text" v-model="min_g" required></v-text-field>
              <v-select
              label="Flight Tickets"
              :items="flight_tickets"
              item-text="pnr"
              item-value="id"
              v-model="selectedFlightTickets"
              multiple
              ></v-select>
              <v-text-field label="Remark" type="text" v-model="remark"></v-text-field>
              <v-btn @click="currentPage = 'tours'">Back</v-btn>
              <v-btn type="submit">Add Tour</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'editTour'">
          <v-card-title>Edit Tour</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="editTour">
              <v-select
                label="Tour Group"
                :items="tour_groups"
                item-text="tour_group_name"
                item-value="id"
                v-model="editingTour.tour_group_id"
                required
              ></v-select>
              <v-text-field label="Airline" type="text" v-model="editingTour.airline" required></v-text-field>
              <v-text-field label="Tour Code" type="text" v-model="editingTour.code" required></v-text-field>
              <label class="my-label">Departure Date</label>
              <flat-pickr
                :modelValue="editingTour.departure_date"
                :config="dateConfig"
                @update:modelValue="value => editingTour.departure_date = value"
              ></flat-pickr>
              <label class="my-label">Return Date</label>
              <flat-pickr
                :modelValue="editingTour.return_date"
                :config="dateConfig"
                @update:modelValue="value => editingTour.return_date = value"
              ></flat-pickr>
              <v-text-field label="Special Price" type="text" v-model="editingTour.sp"></v-text-field>
              <v-text-field label="Tier 1 Price" type="text" v-model="editingTour.tier1"></v-text-field>
              <v-text-field label="Tier 2 Price" type="text" v-model="editingTour.tier2"></v-text-field>
              <v-text-field label="Tier 3 Price" type="text" v-model="editingTour.tier3" required></v-text-field>
              <v-text-field label="Special Price (Commission)" type="text" v-model="editingTour.sp_c"></v-text-field>
              <v-text-field label="Tier 1 Price (Commission)" type="text" v-model="editingTour.tier1_c"></v-text-field>
              <v-text-field label="Tier 2 Price (Commission)" type="text" v-model="editingTour.tier2_c"></v-text-field>
              <v-text-field label="Tier 3 Price (Commission)" type="text" v-model="editingTour.tier3_c" required></v-text-field>
              <v-select
                label="Country"
                :items="countries"
                item-text="name"
                item-value="id"
                v-model="editingTour.country_id"
                required
              ></v-select>
              <v-text-field label="Minimum Group Size" type="text" v-model="editingTour.min_g" required></v-text-field>
              <v-select
              label="Flight Tickets"
              :items="flight_tickets"
              item-text="pnr"
              item-value="id"
              v-model="editingTourFlightTickets"
              multiple
              ></v-select>
              <v-text-field label="Remark" type="text" v-model="editingTour.remark"></v-text-field>
              <v-btn @click="currentPage = 'tours'">Cancel</v-btn>
              <v-btn type="submit">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card v-if="currentPage === 'duplicateTour'">
          <v-card-title>Duplicate Tour</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="duplicateTour">
              <v-select
                label="Tour Group"
                :items="tour_groups"
                item-text="tour_group_name"
                item-value="id"
                v-model="duplicatingTour.tour_group_id"
                required
                :disabled="true"
              ></v-select>
              <v-text-field label="Airline" type="text" v-model="duplicatingTour.airline" required></v-text-field>
              <v-text-field label="Tour Code" type="text" v-model="duplicatingTour.code" required></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field label="Departure Date" type="datetime-local" v-model="duplicatingTour.departure_date" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Return Date" type="datetime-local" v-model="duplicatingTour.return_date" required></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-text-field label="Departure Date" type="text" v-model="duplicatingTour.departure_date" required></v-text-field>
              <v-text-field label="Return Date" type="text" v-model="duplicatingTour.return_date" required></v-text-field> -->
              <v-text-field label="Special Price" type="text" v-model="duplicatingTour.sp"></v-text-field>
              <v-text-field label="Tier 1 Price" type="text" v-model="duplicatingTour.tier1"></v-text-field>
              <v-text-field label="Tier 2 Price" type="text" v-model="duplicatingTour.tier2"></v-text-field>
              <v-text-field label="Tier 3 Price" type="text" v-model="duplicatingTour.tier3" required></v-text-field>
              <v-text-field label="Special Price (Commission)" type="text" v-model="duplicatingTour.sp_c"></v-text-field>
              <v-text-field label="Tier 1 Price (Commission)" type="text" v-model="duplicatingTour.tier1_c"></v-text-field>
              <v-text-field label="Tier 2 Price (Commission)" type="text" v-model="duplicatingTour.tier2_c"></v-text-field>
              <v-text-field label="Tier 3 Price (Commission)" type="text" v-model="duplicatingTour.tier3_c" required></v-text-field>
              <!-- <v-select
                label="Country"
                :items="countries"
                item-text="name"
                item-value="id"
                v-model="editingTour.country_id"
                required
              ></v-select> -->
              <v-text-field label="Minimum Group Size" type="text" v-model="duplicatingTour.min_g" required></v-text-field>
              <v-text-field label="Remark" type="text" v-model="duplicatingTour.remark"></v-text-field>
              <v-btn @click="currentPage = 'tours'">Cancel</v-btn>
              <v-btn type="submit">Duplicate</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-dialog v-model="confirmDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirmDelete = false">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="confirmDeleteItem">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts';
import airportsData from '../../assets/airport.json';
export default {
  components: {
    flatPickr, apexchart: VueApexCharts,
  },
  data() {
    return {
      airports: airportsData,
      selectedFrom: null,
      selectedTo: null,
      name: '',
      email: '',
      password: '',
      currentPage: 'dashboard', // 'countries', 'addCountry', 'editCountry', 'flight_tickets', 'addFlightTicket', 'editFlightTicket'
      editingCountry: {
        name: '',
        img_url: '',
        file: null,
      },
      editingFlightTicket: null,
      addFlightTicket:{
        pnr: '',
        airline: '',
        seat: '',
        departure_date: '',
        from: '',
        to: '',
        return_date: '',
      },
      countryToDelete: null,
      confirmDelete: false,
      itemToDelete: null,
      departure_date: '',
      return_date: '', 
      editingTourFlightTickets: [],
      selectedFlightTickets: [],
      // editingCountryRoles: '',
      searchPackageName: '',
      searchDepartureDate: '',
      searchAirline: '',
      searchText: '',
      searchDate: '',
      duplicatingTour: {},
      tour_groups: [],
      editingTour: {},
      editingTourPackage: {},
      countries: [],
      img_url: '',
      flight_tickets: [],
      tourPackages: [],
      roles: [],
      tours: [],
      img_url: '',
      countryHeaders: [
        { text: 'No', value: 'index' },
        { text: 'Flat', value: 'img_url' },
        { text: 'Country', value: 'name' },
        { text: 'Actions', value: 'actions' },
      ],
      flight_ticketHeaders: [
        { text: 'No', value: 'index' },
        { text: 'PNR', value: 'pnr' },
        { text: 'Airline', value: 'airline' },
        { text: 'Seat', value: 'seat' },
        { text: 'Departure Date', value: 'departure_date' },
        { text: 'From', value: 'from' },
        { text: 'Icon', value: 'icon' },
        { text: 'To', value: 'to' },
        { text: 'Return Date', value: 'return_date' },
        { text: 'Actions', value: 'actions' },
      ],
      tourHeaders: [
        { text: 'No', value: 'index' },
        { text: 'Tour Name', value: 'tour_group.tour_group_name' },
        { text: 'Airline', value: 'airline' },
        { text: 'Code', value: 'code' },
        { text: 'Departure Date', value: 'departure_date' },
        { text: 'Remark', value: 'remark' },
        { text: 'Actions', value: 'actions' },
      ],
      tourPackageHeaders: [
        { text: 'No', value: 'index' },
        { text: 'Country', value: 'country.name' },
        { text: 'Tour Group Name', value: 'tour_group_name' },
        { text: 'Tour Group Name Chinese', value: 'tour_group_name_chinese' },
        // { text: 'Tour Image URL', value: 'tour_img_url' },
        { text: 'Actions', value: 'actions' },
      ],
      labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      value: [10, 15, 7, 20, 10],
      // editingTour: {
      //   departure_date: '',
      //   return_date: '',
      //   // ...
      // },
      dateConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        // defaultDate: this.editingTour.departure_date, // Add this line
      },
      dateConfigSearch: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        allowInput: true,
        showClearDate: true,
      },
      groupSeries: [],
      // groupChartOptions: {
      //   chart: {
      //     type: 'donut',
      //   },
      //   labels: [], // 这里将用于显示组名
      // },
      groupChartOptions: {
        chart: {
          type: 'donut',
          height: 350
        },
        series: [{
          name: 'Sales',
          data: this.groupSeries // This assumes you're keeping the numerical data for charting
        }],
        tooltip: {
          y: {
            formatter: (val) => {
              // Now 'this' refers to the Vue instance, where $formatCurrency is defined
              const formattedValue = this.$formatCurrency(val); // Assuming 'RM' is default
              return formattedValue;
            }
          }
          // y: {
          //   formatter: function (val) {
          //     // return `RM: ${val.toFixed(2)}`;
          //     const formattedValue = this.$formatCurrency(val); // Assuming 'RM' is default
          //     return formattedValue;
          //   }
          // }
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     // return `Total: $${val.toFixed(2)}`;
        //   }
        // }
      },
      groupSeries4: [],
      groupChartOptions4: {
        chart: {
          type: 'donut',
        },
        labels: [], // 这里将用于显示组名

        tooltip: {
          y: {
            formatter: (val) => {
              // Now 'this' refers to the Vue instance, where $formatCurrency is defined
              const formattedValue = this.$formatCurrency(val); // Assuming 'RM' is default
              return formattedValue;
            }
          }
        },
      },
      groupChartOptions2: {
        chart: {
          type: 'polarArea',
        },
        labels: [], // 这里将用于显示组名
      },
      groupDataLoaded: false,
      users: [],
      userTotals: [],
      bookings: [],
      airlines: [
        { name: 'Airasia', code: 'Airasia' },
        { name: 'Malaysia Airlines', code: 'Malaysia Airlines' },
        { name: 'Batik Air', code: 'Batik Air' },
        { name: 'Other', code: '1' }
      ],
      selectedAirline: null,
      customAirline: '',
    }
  },
  mounted() {
    this.getCountries();
    this.getTourGroups();
    this.getFlightTickets();
    this.getTours();
    this.getUsers();
    this.getBookings();
    this.getGroups().then(() => {      

      this.groupSeries = Object.values(this.bookings.reduce((acc, booking) => {
        if (!acc[booking.user_id]) {
          acc[booking.user_id] = 0;
        }
        acc[booking.user_id] += booking.total;
        return acc;
      }, {}));

      // New method to sort user id accordingly
      const userIds = [...new Set(this.bookings.map(booking => booking.user_id))];
      userIds.sort((a, b) => a - b);
      // Map sorted user IDs to corresponding user names
      this.groupChartOptions.labels = userIds.map(userId => {
          const user = this.users.find(user => user.id === userId);
          // return user ? user.name : '';
          return user ? user.name.charAt(0).toUpperCase() + user.name.slice(1) : '';
      });

      // // OLD method user id not sorting so matching wrong data
      // this.groupChartOptions.labels = [...new Set(this.bookings.map(booking => {
      //   const user = this.users.find(user => user.id === booking.user_id);
      //   return user? user.name : '';
      // }))];
      
      // this.groupSeries4 = [40, 50, 90];
      
      this.groupSeries4 = Object.values(this.bookings.reduce((acc, booking) => {
        if (!acc[booking.tour_id]) {
          acc[booking.tour_id] = 0;
        }
        acc[booking.tour_id] += booking.total;
        return acc;
      }, {}));
      
      this.groupChartOptions4.labels = [...new Set(this.bookings.map(booking => {
        // const user = this.users.find(user => user.id === booking.tour_id);
        // return user? user.name : '';
        const tour = this.tours.find(tour => tour.id === booking.tour_id);
        // return tour? tour.package_name : '';
        // return tour ? tour.package_name.slice(0, 14) + '..' : 'unknown';
        return tour ? tour.code + ' (' + tour.airline + ') ' : 'unknown';
        // return tour ? tour.package_name.slice(0, tour.package_name.indexOf(',')) + '..' : ''; // Slice text after comma 

      }))];

      setTimeout(() => {
        this.groupDataLoaded = true;
      }, 1000);
    });
  },
  computed: {
  //   totalSeats() {
  //     if (Array.isArray(this.viewingTour.flight_tickets)) {
  //       return this.viewingTour.flight_tickets.reduce((total, ticket) => total + Number(ticket.seat), 0);
  //     } else {
  //       return 0;
  //     }
  //   },
  //   // other computed properties...
      // fromAirport() {
      //   return this.airports.find(airport => airport.IATA === this.selectedFrom);
      // },
      // toAirport() {
      //   return this.airports.find(airport => airport.IATA === this.selectedTo);
      // },
      canViewTour() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasTourViewPermission = permissions.includes('tour_view');
        console.log("Can view tour:", hasTourViewPermission); // 输出是否有权限
        return hasTourViewPermission;
      },
      canCreateTour() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasTourCreatePermission = permissions.includes('tour_create');
        console.log("Can create tour:", hasTourCreatePermission); // 输出是否有权限
        return hasTourCreatePermission;
        },
      canEditTour() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasTourEditPermission = permissions.includes('tour_edit');
        console.log("Can edit tour:", hasTourEditPermission); // 输出是否有权限
        return hasTourEditPermission;
      },
      canDeleteTour() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasTourDeletePermission = permissions.includes('tour_delete');
        console.log("Can delete tour:", hasTourDeletePermission); // 输出是否有权限
        return hasTourDeletePermission;
      },
      canViewCountry() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasCountryViewPermission = permissions.includes('country_view');
        console.log("Can view country:", hasCountryViewPermission); // 输出是否有权限
        return hasCountryViewPermission;
      },
      canCreateCountry() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasCountryCreatePermission = permissions.includes('country_create');
        console.log("Can create country:", hasCountryCreatePermission); // 输出是否有权限
        return hasCountryCreatePermission;
      },
      canEditCountry() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasCountryEditPermission = permissions.includes('country_edit');
        console.log("Can edit country:", hasCountryEditPermission); // 输出是否有权限
        return hasCountryEditPermission;
      },
      canDeleteCountry() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasCountryDeletePermission = permissions.includes('country_delete');
        console.log("Can delete country:", hasCountryDeletePermission); // 输出是否有权限
        return hasCountryDeletePermission;
      },
      canViewFlightTicket() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasFlightTicketViewPermission = permissions.includes('flight_ticket_view');
        console.log("Can view flight ticket:", hasFlightTicketViewPermission); // 输出是否有权限
        return hasFlightTicketViewPermission;
      },
      canCreateFlightTicket() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasFlightTicketCreatePermission = permissions.includes('flight_ticket_create');
        console.log("Can create flight ticket:", hasFlightTicketCreatePermission); // 输出是否有权限
        return hasFlightTicketCreatePermission;
      },
      canEditFlightTicket() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasFlightTicketEditPermission = permissions.includes('flight_ticket_edit');
        console.log("Can edit flight ticket:", hasFlightTicketEditPermission); // 输出是否有权限
        return hasFlightTicketEditPermission;
      },
      canDeleteFlightTicket() {
        const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
        const hasFlightTicketDeletePermission = permissions.includes('flight_ticket_delete');
        console.log("Can delete flight ticket:", hasFlightTicketDeletePermission); // 输出是否有权限
        return hasFlightTicketDeletePermission;
      },
      filteredTours() {
      return this.tours.filter(tour => {
        return (!this.searchPackageName || tour.package_name.toLowerCase().includes(this.searchPackageName.toLowerCase())) &&
        (!this.searchDepartureDate || moment(tour.departure_date).format('YYYY-MM-DD') === moment(this.searchDepartureDate).format('YYYY-MM-DD')) &&
        (!this.searchAirline || tour.airline.toLowerCase().includes(this.searchAirline.toLowerCase()));
      });
    },
    filteredFlightTickets() {
      return this.flight_tickets.filter(ticket => {
        let matchesText = true;
        let matchesDate = true;

        if (this.searchText) {
          // 这里假设你想在 'pnr', 'airline', 'to' 这些字段中搜索
          matchesText = ['pnr', 'airline', 'to'].some(field => 
            String(ticket[field]).toLowerCase().includes(this.searchText.toLowerCase())
          );
        }

        if (this.searchDate) {
          matchesDate = moment(ticket.departure_date).format('YYYY-MM-DD') === moment(this.searchDate).format('YYYY-MM-DD');
        }

        return matchesText && matchesDate;
      });
    },
  },
  methods: {
    checkIfOtherSelected() {
      if (this.selectedAirline && this.selectedAirline === '1') {
        this.addFlightTicket.airline = this.customAirline;  // 使用自定义航空公司名称
      } else {
        this.addFlightTicket.airline = this.selectedAirline;
      }
    },
    airportText(item) {
      return `${item.IATA} - ${item['Airport Name']} (${item.City}, ${item.Country})`;
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.IATA.toLowerCase();
      const textTwo = item['Airport Name'].toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    getTourGroups() {
      axios.get('/api/tour-groups')
      .then(response => {
        this.tour_groups = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    showCountriesPage() {
      this.currentPage = 'countries';
    },
    showAddCountryPage() {
      this.currentPage = 'addCountry';
    },
    showEditCountryPage(country) {
      this.editingCountry = Object.assign({}, country);
      this.editingCountryFlightTickets = country.flight_tickets && country.flight_tickets.length > 0 ? country.flight_tickets[0].id : null;
      this.editingCountryRoles = country.roles && country.roles.length > 0 ? country.roles[0].id : null;
      // this.editingCountry.img_url = null;
      this.currentPage = 'editCountry';
    },
    getCountries() {
      axios.get('/api/countries')
      .then(response => {
        this.countries = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    addCountry() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('img_url', this.img_url);
      axios.post('/api/countries', formData)
      .then(response => {
        console.log(response.data);
        // Redirect to countries page after successful addition
        this.currentPage = 'countries';
        this.getCountries();
        // Reset the form fields
        this.name = '';
        this.img_url = '';
      })
      // axios.post('/api/countries', {
      //   name: this.name,
      //   img_url: this.img_url,
      // })
      // .then(response => {
      //   console.log(response.data);
      //   // Redirect to countries page after successful addition
      //   this.currentPage = 'countries';
      //   this.getCountries();
      //   // Reset the form fields
      //   this.name = '';
      //   this.img_url = '';
      // })
      // .catch(error => {
      //   console.log(error);
      // });
    },
    editCountry() {
      const formData = new FormData();
      formData.append('name', this.editingCountry.name);
      
      if (this.editingCountry.img_url instanceof File) {
        formData.append('img_url', this.editingCountry.img_url);
      } else if (typeof this.editingCountry.img_url === 'string' && this.editingCountry.img_url.startsWith('data:image')) {
        // 如果是 base64 编码的图片数据
        fetch(this.editingCountry.img_url)
          .then(res => res.blob())
          .then(blob => {
            formData.append('img_url', blob, 'image.png');
            this.sendEditRequest(formData);
          });
        return;
      }
      
      this.sendEditRequest(formData);
    },

    sendEditRequest(formData) {
      // 打印formData内容，用于调试
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ': ' + pair[1]); 
      }

      axios.post(`/api/countries/${this.editingCountry.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-HTTP-Method-Override': 'PUT'
        }
      })
      .then(response => {
        console.log(response.data);
        this.currentPage = 'countries';
        this.getCountries();
      })
      .catch(error => {
        console.error('Update error:', error.response.data);
      });
    },
    startDeletingCountry(country) {
    this.itemToDelete = country;
    this.confirmDelete = true;
    },
    triggerFileSelect() {
      console.log(this.$refs.fileInput); // Check the structure in the console
      const input = this.$refs.fileInput.$refs.input;
      if (input) {
        input.click();
      } else {
        console.error('File input not found');
      }
    },
    onCountryFileChange(event) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        if (this.currentPage === 'addCountry') {
          this.img_url = file;
        } else if (this.currentPage === 'editCountry') {
          this.editingCountry.img_url = file;
        }
      }
    },
    beforeDestroy() {
      if (this.img_url.startsWith('blob:')) {
        URL.revokeObjectURL(this.img_url);
      }
    },
    uploadCountryImage() {

      this.editingCountry.img_url = this.img_url;
    },
    // onCountryFileChange(event) {
    //   const file = event.target.files[0]; 
    //   if (file) {
    //     this.editingCountry.img_url = file;
    //     this.img_url = file;
    //   }
    // },

    showFlightTicketsPage() {
      this.currentPage = 'flight_tickets';
    },
    showAddFlightTicketPage() {
      this.currentPage = 'addShowFlightTicket';
    },
    showEditFlightTicketPage(flight_ticket) {
      this.editingFlightTicket = Object.assign({}, flight_ticket);
      this.currentPage = 'editFlightTicket';
    },

    getFlightTickets() {
      axios.get('/api/flight_tickets')
      .then(response => {
        this.flight_tickets = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    submitFlightTicket() {
      this.checkIfOtherSelected();  // 确保在提交前更新 airline
      axios.post('/api/flight_tickets', {
        pnr: this.addFlightTicket.pnr,
        airline: this.addFlightTicket.airline,
        departure_date: this.addFlightTicket.departure_date,
        return_date: this.addFlightTicket.return_date,
        from: this.selectedFrom ? this.selectedFrom.IATA : null,
        to: this.selectedTo ? this.selectedTo.IATA : null,
        seat: this.addFlightTicket.seat,
      })
      .then(response => {
        console.log(response.data);
        this.currentPage = 'flight_tickets';
        this.getFlightTickets();
        this.addFlightTicket = {};  // 重置表单字段
        this.selectedFrom = null;
        this.selectedTo = null;
        this.selectedAirline = null;
      })
      .catch(error => {
        console.log(error);
      });
    },
    editFlightTicket() {
      const flightTicketData = {
        ...this.editingFlightTicket,
        from: this.editingFlightTicket.from ? this.editingFlightTicket.from.IATA : null,
        to: this.editingFlightTicket.to ? this.editingFlightTicket.to.IATA : null,
      };
      axios.put(`/api/flight_tickets/${this.editingFlightTicket.id}`, flightTicketData)
        .then(response => {
          console.log(response.data);
          // Redirect to flight_tickets page after successful update
          this.editingflight_ticket = null;
          this.currentPage = 'flight_tickets';
          this.getFlightTickets();
        })
        .catch(error => {
          console.log(error);
        });
    },
    startDeletingFlightTicket(flight_ticket) {
      this.itemToDelete = flight_ticket;
      this.confirmDelete = true;
    },
  
    showToursPage() {
      this.currentPage = 'tours';
    },
    showViewTourPage(tour) {
      this.viewingTour = { ...tour, flight_tickets: tour.flight_tickets || [] };
      this.currentPage = 'viewTour';
    },
    showAddTourPage() {
      this.currentPage = 'addTour';
    },
    // showEditTourPage(tour) {
    //   this.editingTour = Object.assign({}, tour);
    //   this.editingTourFlightTickets = tour.flight_tickets.map(ticket => ticket.id);
    //   this.currentPage = 'editTour';
    // },
    showEditTourPage(tour) {
      this.editingTour = {
        ...tour,
        departure_date: moment(tour.departure_date).toISOString(),
        return_date: moment(tour.return_date).toISOString(),
      };
      this.editingTourFlightTickets = tour.flight_tickets.map(ticket => ticket.id);
      this.currentPage = 'editTour';
    },
    updateDepartureDate(date) {
      this.editingTour.departure_date = date;
    },
    updateReturnDate(date) {
      this.editingTour.return_date = date;
    },
    showDuplicateTourPage(tour) {
      this.duplicatingTour = Object.assign({}, tour);
      this.duplicatingTour.departure_date = '';
      this.duplicatingTour.return_date = '';
      this.currentPage = 'duplicateTour';
    },
    getTours() {
      axios.get('/api/tours')
      .then(response => {
        this.tours = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    addTour() {
      axios.post('/api/tours', {
        tour_group_id: this.tour_group_id,
        airline: this.airline,
        code: this.code,
        departure_date: this.departure_date,
        return_date: this.return_date,
        sp: this.sp,
        tier1: this.tier1,
        tier2: this.tier2,
        tier3: this.tier3,
        sp_c: this.sp_c,
        tier1_c: this.tier1_c,
        tier2_c: this.tier2_c,
        tier3_c: this.tier3_c,
        country_id: this.country_id,
        min_g: this.min_g,
        flight_tickets: this.selectedFlightTickets,
        remark: this.remark,
        groups: this.newTourGroups,
        roles: this.newTourRoles
      })
      .then(response => {
        console.log(response.data);
        this.currentPage = 'tours';
        this.getTours();
        this.name = '';
        this.email = '';
        this.password = '';
        this.newTourGroups = [];
        this.newTourRoles = [];
      })
      .catch(error => {
        console.log(error);
      });
    },
    editTour() {
      const tourData = {
        ...this.editingTour,
        departure_date: moment(this.editingTour.departure_date).format('YYYY-MM-DD HH:mm:ss'),
        return_date: moment(this.editingTour.return_date).format('YYYY-MM-DD HH:mm:ss'),
        flight_tickets: this.editingTourFlightTickets,
      };
      if (this.editingTour.password) {
        tourData.password = this.editingTour.password;
      }
      axios.put(`/api/tours/${this.editingTour.id}`, tourData)
      .then(response => {
        console.log(response.data);
        this.currentPage = 'tours';
        this.getTours();
        this.editingTourFlightTickets = [];
      })
      .catch(error => {
        console.log(error);
      });
    },
    duplicateTour() {
      axios.post(`/api/tours/${this.duplicatingTour.id}/duplicate`, this.duplicatingTour)
        .then(response => {
          // Handle success
          this.currentPage = 'tours';
          this.getTours();
        })
        .catch(error => {
          // Handle error
        });
    },
    startDeletingTour(tour) {
      this.itemToDelete = tour;
      this.confirmDelete = true;
    },
    
    showTourPackagesPage() {
      this.currentPage = 'tour_packages';
    },
    filteredTourPackages() {
       return this.tour_groups;
    },
    showEditTourPackagePage(tour_group) {
      this.editingTourPackage = Object.assign({}, tour_group);
      this.currentPage = 'editTourPackage';
    },
    editTourPackage() {
      const tourPackageData = {
        ...this.editingTourPackage,
        country_id: this.editingTourPackage.country_id,
        tour_group_name: this.editingTourPackage.tour_group_name,
        tour_group_name_chinese: this.editingTourPackage.tour_group_name_chinese,
        tour_img_url: this.editingTourPackage.tour_img_url,
      };
      axios.put(`/api/tour-groups/${this.editingTourPackage.id}`, tourPackageData)
        .then(response => {
          console.log(response.data);
          // Redirect to flight_tickets page after successful update
          this.editingTourPackage = null;
          this.currentPage = 'tour_packages';
          this.getTourGroups();
        })
        .catch(error => {
          console.log(error);
        });
    },
    addTourPackage() {
      axios.post('/api/tour-groups', {
        country_id: this.country_id,
        tour_group_name: this.tour_group_name,
        tour_group_name_chinese: this.tour_group_name_chinese,
        tour_img_url: this.tour_img_url,
      })
      .then(response => {
        console.log(response.data);
        this.currentPage = 'tour_packages';
        this.tour_group_name = '';
        this.tour_group_name_chinese = '';
        this.tour_img_url = '';
        this.getTourGroups();
      })
      .catch(error => {
        console.log(error);
      });
    },
    startDeletingTourPackage(tour_group) {
      this.itemToDelete = tour_group;
      this.confirmDelete = true;
    },
    confirmDeleteItem() {
      if (this.currentPage === 'countries') {
        axios.delete(`/api/countries/${this.itemToDelete.id}`)
          .then(() => {
            // Remove the country from the countries array
            this.countries = this.countries.filter(u => u.id !== this.itemToDelete.id);
            this.confirmDelete = false;
            this.getCountries();
          })
          .catch(error => {
            console.log(error);
          });
      } else if (this.currentPage === 'flight_tickets') {
        axios.delete(`/api/flight_tickets/${this.itemToDelete.id}`)
          .then(() => {
            // Remove the flight_ticket from the flight_tickets array
            this.flight_tickets = this.flight_tickets.filter(g => g.id !== this.itemToDelete.id);
            this.confirmDelete = false;
            this.getFlightTickets();
          })
          .catch(error => {
            console.log(error);
          });
      }
      else if (this.currentPage === 'tour_packages') {
        axios.delete(`/api/tour-groups/${this.itemToDelete.id}`)
          .then(() => {
            this.confirmDelete = false;
            this.getTourGroups();
          })
          .catch(error => {
            console.log(error);
          });
      }
      else if (this.currentPage === 'tours') {
        axios.delete(`/api/tours/${this.itemToDelete.id}`)
          .then(() => {
            this.confirmDelete = false;
            this.getTours();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    getBookings() {
      axios.get('/api/bookings')
        .then(response => {
          this.bookings = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getUsers() {
      axios.get('/api/users')
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getGroups() {
      return axios.get('/api/groups')
      .then(response => {
        this.groups = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },

    getUserTotals() {
      const userTotals = this.users.map(user => {
        const totalAmount = this.bookings
          .filter(booking => booking.user_id === user.id)
          .reduce((total, booking) => total + booking.total, 0);

        return {
          userName: user.name,
          totalAmount: totalAmount
        };
      });

      return userTotals;
    },
    
  }
};
</script>
<style  scoped>
  
</style>